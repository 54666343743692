import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slot-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: [..._ctx.btnClass, _ctx.severity]
  }), [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
            key: 0,
            size: 16
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 16))
}