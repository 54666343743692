import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "notifications" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_badge = _resolveDirective("badge")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.notifications.length)
      ? _withDirectives((_openBlock(), _createBlock(_component_MButton, {
          key: 0,
          icon: _ctx.IconBell,
          rounded: "",
          onClick: _ctx.toggle
        }, null, 8, ["icon", "onClick"])), [
          [_directive_tooltip, 'Notificaciónes'],
          [
            _directive_badge,
            _ctx.notifications.length,
            void 0,
            { danger: true }
          ]
        ])
      : _withDirectives((_openBlock(), _createBlock(_component_MButton, {
          key: 1,
          icon: _ctx.IconBell,
          rounded: ""
        }, null, 8, ["icon"])), [
          [_directive_tooltip, 'Sin notificaciones']
        ]),
    _createVNode(_component_OverlayPanel, { ref: "op" }, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("header", null, "Notificaciones", -1)),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
            return (_openBlock(), _createBlock(_component_Notification, {
              key: index,
              class: "notification",
              notification: notification,
              onAcceptTeamInvitation: 
						() =>
							_ctx.acceptTeamInvitation(
								notification.id,
								notification.team.uuid
							)
					,
              onRejectTeamInvitation: 
						() =>
							_ctx.rejectTeamInvitation(
								notification.id,
								notification.team.uuid
							)
					
            }, null, 8, ["notification", "onAcceptTeamInvitation", "onRejectTeamInvitation"]))
          }), 128))
        ])
      ]),
      _: 1
    }, 512)
  ]))
}