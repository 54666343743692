import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-input" }
const _hoisted_2 = ["for", "disabled"]
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['input-wrapper'])
}
const _hoisted_4 = ["value"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconEye = _resolveComponent("IconEye")!
  const _component_IconEyeClosed = _resolveComponent("IconEyeClosed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.$attrs && _ctx.$attrs.id ? String(_ctx.$attrs.id) : '',
          disabled: _ctx.$attrs.disabled,
          style: _normalizeStyle(`pointer-events: ${_ctx.$attrs.disabled ? 'none' : 'auto'}`)
        }, _toDisplayString(_ctx.label), 13, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", _mergeProps({ ref: "inputRef" }, _ctx.$attrs, {
        value: _ctx.modelValue,
        class: [..._ctx.inputClass, _ctx.severity],
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
      }), null, 16, _hoisted_4),
      (_ctx.smallText)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            id: _ctx.$attrs && _ctx.$attrs.id ? String(_ctx.$attrs.id) : '',
            class: _normalizeClass([_ctx.severity])
          }, _toDisplayString(_ctx.smallText), 11, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.$attrs.type === 'password')
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "eye-icon-wrapper",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePassVisibility && _ctx.handlePassVisibility(...args)))
          }, [
            (_ctx.isPassHidden)
              ? (_openBlock(), _createBlock(_component_IconEye, {
                  key: 0,
                  size: 18
                }))
              : (_openBlock(), _createBlock(_component_IconEyeClosed, {
                  key: 1,
                  size: 18
                }))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}