
import { defineComponent, ref, onMounted, computed } from 'vue';

const __default__ = defineComponent({
	name: 'MAvatar',
	props: {
		imgUrl: {
			type: String,
			required: false,
			default: ''
		},
		name: {
			type: String,
			required: false,
			default: ''
		},
		size: {
			type: Number,
			required: false,
			default: 100
		},
		rounded: {
			type: Boolean,
			required: false,
			default: false
		},
		color: {
			type: String,
			required: false,
			default: '#30343e'
		}
	},
	emits: [],
	setup(props) {
		const containerRef = ref();
		const fontSize = ref('1rem');
		const borderRadius = ref('8px');

		onMounted(() => {
			// The font size should be 50% of the container width
			fontSize.value = `${props.size * 0.5}px`;
			borderRadius.value = props.rounded ? '50%' : '8px';
		});

		const style = computed(() => {
			return `min-width: ${props.size}px;
            min-height: ${props.size}px;
            max-width: ${props.size}px;
            max-height: ${props.size}px;
            background-color: ${props.color};
            border: solid ${props.imgUrl ? '0px' : '2px'} ${props.color}
		`;
		});

		return {
			containerRef,
			fontSize,
			borderRadius,
			style
		};
	}
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "170e87b6": (_ctx.borderRadius),
  "14d99cb7": (_ctx.fontSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__