import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification team-invitation"
}
const _hoisted_2 = { class: "message" }
const _hoisted_3 = { class: "dates" }
const _hoisted_4 = { class: "date-displayer" }
const _hoisted_5 = { class: "date-displayer" }
const _hoisted_6 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MAvatar = _resolveComponent("MAvatar")!
  const _component_IconCalendar = _resolveComponent("IconCalendar")!
  const _component_IconClock = _resolveComponent("IconClock")!
  const _component_MButton = _resolveComponent("MButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.notification.type === 'teamInvitation' && _ctx.notification.team)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MAvatar, {
          "img-url": _ctx.notification.team.logoUrl,
          name: _ctx.notification.team.name,
          size: 55,
          style: 'margin-right: 1rem'
        }, null, 8, ["img-url", "name"]),
        _createElementVNode("span", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Invitación de equipo", -1)),
          _createElementVNode("p", null, [
            _cache[2] || (_cache[2] = _createTextVNode(" Has sido invitado a unirte a ")),
            _createElementVNode("b", null, _toDisplayString(_ctx.notification.team.name), 1)
          ]),
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_IconCalendar, { size: 18 }),
              _createTextVNode(" " + _toDisplayString(_ctx.notificationDate), 1)
            ]),
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_IconClock, { size: 18 }),
              _createTextVNode(" " + _toDisplayString(_ctx.notificationHour), 1)
            ])
          ])
        ]),
        _createElementVNode("span", _hoisted_6, [
          _withDirectives(_createVNode(_component_MButton, {
            class: "action-btn",
            rounded: "",
            severity: "success",
            icon: _ctx.IconCheck,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('accept-team-invitation')))
          }, null, 8, ["icon"]), [
            [
              _directive_tooltip,
              'Unirme',
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives(_createVNode(_component_MButton, {
            class: "action-btn",
            rounded: "",
            severity: "danger",
            icon: _ctx.IconX,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reject-team-invitation')))
          }, null, 8, ["icon"]), [
            [
              _directive_tooltip,
              'Rechazar',
              void 0,
              { top: true }
            ]
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}