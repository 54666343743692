import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "socials-column" }
const _hoisted_2 = { class: "socials-wrapper" }
const _hoisted_3 = { class: "terms-column" }
const _hoisted_4 = { class: "terms" }

import {
	IconBrandInstagram,
	IconBrandTwitter,
	IconBrandDiscord,
	IconBrandYoutube
} from '@tabler/icons-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MFooter',
  setup(__props) {

const redirectToSocial = (socialHref: string) => {
	const a = document.createElement('a');
	a.target = '_blank';
	a.href = socialHref;
	a.click();
};

const socialsBtns = [
	{
		tooltip: 'Instagram',
		icon: IconBrandInstagram,
		url: 'https://www.instagram.com/elunderesports'
	},
	{
		tooltip: 'Youtube',
		icon: IconBrandYoutube,
		url: 'https://www.youtube.com/@elunderesports'
	},
	{
		tooltip: 'Twitter',
		icon: IconBrandTwitter,
		url: 'https://x.com/ElUnderEsports'
	},
	{
		tooltip: 'Discord',
		icon: IconBrandDiscord,
		url: 'https://discord.gg/aKZawmKNMD'
	}
];

return (_ctx: any,_cache: any) => {
  const _component_MButton = _resolveComponent("MButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("span", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h6", null, "Redes", -1)),
      _createElementVNode("span", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(socialsBtns, (social, index) => {
          return _createElementVNode("span", { key: index }, [
            _withDirectives(_createVNode(_component_MButton, {
              rounded: "",
              outlined: "",
              icon: social.icon,
              size: "medium",
              onClick: () => redirectToSocial(social.url)
            }, null, 8, ["icon", "onClick"]), [
              [
                _directive_tooltip,
                social.tooltip,
                void 0,
                { top: true }
              ]
            ])
          ])
        }), 64))
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "contact-column" }, [
      _createElementVNode("h6", null, "Contacto"),
      _createElementVNode("p", null, "el.under.esports@gmail.com")
    ], -1)),
    _createElementVNode("span", _hoisted_3, [
      _cache[4] || (_cache[4] = _createElementVNode("h6", null, "Terminos", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", {
          class: "terms-link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'privacyPolicy' })))
        }, " Politica de Privacidad "),
        _cache[3] || (_cache[3] = _createTextVNode(" | ")),
        _createElementVNode("span", {
          class: "terms-link",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'termsOfService' })))
        }, " Termninos de Uso ")
      ])
    ])
  ]))
}
}

})