import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Image as UnpicImage } from '@unpic/vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'MImage',
  props: {
	src: {
		type: String,
		required: true
	}
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UnpicImage), _mergeProps(_ctx.$attrs, { src: __props.src }), null, 16, ["src"]))
}
}

})