import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref } from "vue"

const _hoisted_1 = { id: "nav" }
const _hoisted_2 = {
  key: 0,
  class: "main-navigation-wrapper"
}
const _hoisted_3 = { class: "icon-wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "actions"
}
const _hoisted_5 = { key: 2 }

import { computed, ref } from 'vue';

// Vue Router
import { useRouter } from 'vue-router';

// Icons
import {
	IconTrophy,
	IconSwords,
	IconSortAscending2,
	IconMenu2,
	IconLogin2
} from '@tabler/icons-vue';

// PrimeVUe
import Menu from 'primevue/menu';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  props: {
	isMobile: {
		type: Boolean,
		required: false,
		default: false
	}
},
  setup(__props) {



const router = useRouter();

// Navigation
const navigateToLogin = () => {
	router.push({ name: 'login' });
};
const navigateToRegister = () => {
	router.push({ name: 'register' });
};

const currentPathName = computed(() => router.currentRoute.value.name);

const navigationItems = computed(() => [
	{
		to: '/torneos',
		icon: IconTrophy,
		text: 'Torneos',
		key: 'tournaments'
	},
	{
		to: '/ranking',
		icon: IconSortAscending2,
		text: 'Ranking',
		key: 'ranking'
	},
	{
		to: '/lobby',
		icon: IconSwords,
		text: 'Scrims',
		key: 'lobby'
	}
]);

const menu = ref();
const items = ref([
	{
		label: 'Navegacion',
		items: [
			{
				label: 'Torneos',
				icon: 'pi pi-home',
				command: () => router.push({ name: 'tournaments' })
			},
			{
				label: 'Ranking',
				icon: 'pi pi-list',
				command: () => router.push({ name: 'ranking' })
			},
			{
				label: 'Scrims',
				icon: 'pi pi-shield',
				command: () => router.push({ name: 'lobby' })
			}
		]
	},
	{
		label: 'Plataforma',
		items: [
			{
				label: 'Ingresar',
				icon: 'pi pi-sign-in',
				command: () => router.push({ name: 'login' })
			},
			{
				label: 'Registrarme',
				icon: 'pi pi-user-plus',
				command: () => router.push({ name: 'register' })
			}
		]
	}
]);

const toggleMenu = (event: any) => {
	menu.value.toggle(event);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "logo" }, null, -1)),
    (!__props.isMobile)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigationItems.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass({ selected: currentPathName.value === item.key })
            }, [
              _createVNode(_component_router_link, {
                to: item.to,
                class: "nav-item"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { size: 28 }))
                  ]),
                  _createElementVNode("p", null, _toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!__props.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", null, [
            _createVNode(_component_MButton, {
              outlined: "",
              onClick: navigateToLogin
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Ingresar ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("span", null, [
            _createVNode(_component_MButton, { onClick: navigateToRegister }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Registrarme ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (__props.isMobile)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createVNode(_component_MButton, {
            rounded: "",
            outlined: "",
            icon: _unref(IconMenu2),
            onClick: toggleMenu
          }, null, 8, ["icon"]),
          _createVNode(_unref(Menu), {
            id: "responsive_menu",
            ref_key: "menu",
            ref: menu,
            model: items.value,
            popup: ""
          }, null, 8, ["model"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})