import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "view-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MFooter = _resolveComponent("MFooter")!
  const _component_MLoader = _resolveComponent("MLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast),
    _createVNode(_component_ConfirmDialog),
    (!_ctx.isLoadingApp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_SideBar, {
                key: 0,
                "is-mobile": _ctx.isMobile,
                onSettings: _ctx.navigateToSettings,
                onLogOut: _ctx.logOut
              }, null, 8, ["is-mobile", "onSettings", "onLogOut"]))
            : (_openBlock(), _createBlock(_component_NavBar, {
                key: 1,
                class: "navbar",
                "is-mobile": _ctx.isMobile
              }, null, 8, ["is-mobile"])),
          _createVNode(_component_router_view, { class: "router-view" }),
          (!_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_MFooter, { key: 2 }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_MLoader, { key: 1 }))
  ]))
}